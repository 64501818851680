<template>
  <div :class="`m-3 p-2 wrapper wrapper--default`">
    <Loading v-if="loading" />
    <div v-else>
      <div class="w100 flexbox">
        <h3 class="w100">Algemeen</h3>
        <hr class="m2 programs_hr w100" />
        <ProgramCard
          :link="`https://oneworld.network.kleyn.com:1081/jde/E1Menu.maf`"
          title="OneWorld"
          :icon="`https://apps.kleyn.com/images/oneworld.png`"
        />
        <ProgramCard
          :link="`https://kleyn.crm4.dynamics.com/`"
          title="CRM"
          :icon="`https://apps.kleyn.com/images/dynamicscrm.png`"
        />
        <ProgramCard
          :link="`https://www.youtube.com/playlist?list=PLVVpi5puQMT6YcPxLmoZSzk75_M6qLZMP`"
          title="CRM instructievideos"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/video-tutorial.png`"
        />
        <ProgramCard
          :link="`https://kleyn.crm4.dynamics.com/main.aspx?appid=a1971671-a4b2-e911-a849-000d3a2bd64e&pagetype=entitylist&etn=incident&viewid=00000000-0000-0000-00aa-000010001030&viewType=1039`"
          title="CRM Cases"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/wrench.png`"
        />
        <ProgramCard
          :link="`https://kleyngroup.atlassian.net/servicedesk/customer/portal/1`"
          title="Helpdesk ICT"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/it_support.png`"
        />
        <ProgramCard
          :link="`https://app.powerbi.com`"
          title="PowerBI Portaal"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/powerbi.png`"
        />
        <ProgramCard
          :link="`https://learning.awaretrain.com/nl/9DLEJE1zxY/login`"
          title="AwareTrain"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/awaretrain.png`"
        />
        <ProgramCard
          :link="`https://online.emea.adp.com/signin/v1?APPID=IHCM&productId=b376f1f2-a35a-025b-e053-f282530b8ccb&returnURL=https://ihcm.adp.com/_index/&callingAppId=IHCM&TARGET=-SM-https://ihcm.adp.com/_index/`"
          title="ADP"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/ADP.png`"
        />
      </div>
      <div v-for="(group, key) in programs" :key="key" class="flexbox w100">
        <div class="w100">
          <h3>{{ key }}</h3>
        </div>
        <hr class="w100 m0 programs_hr" />
        <div class="flex _row w100">
          <ProgramCard
            v-for="(program, key) in group"
            :key="key"
            :link="`kleyn:${program.Tag}`"
            :title="`${program.Titel}`"
            :icon="`https://apps.kleyn.com/images/${program.Icon}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ProgramCard from "@/components/ProgramCard.vue";

export default {
  components: { Loading, ProgramCard },
  data: () => ({
    programs: null,
    loading: true,
    name: null,
  }),
  created() {
    this.name = this.msalInstance.getAllAccounts()[0].idTokenClaims.preferred_username;
    this.getData();
  },
  methods: {
    getData() {
      request(`programs/${this.name}`, "GET").then(({ programs }) => {
        this.programs = programs;
        this.loading = false;
      });
    },
  },
};
</script>
